const requestUrl =
      'https://api.unsplash.com/photos/random?topics=nature,travel,abstract&client_id=wj-EbzRDipD7d3bW91f7Nx6cPXsumkltIa0rp3TdCI4';

var credit = document.querySelectorAll("a.credit")
  
fetch(requestUrl).then((response) => response.json()).then((data) => {
	
	console.log(data.user)
	credit[0].href = data.user.links.html
	credit[0].innerHTML = data.user.name
	document.body.style.backgroundImage = "url('" + data.urls.full + "')"

});    



